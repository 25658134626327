<template>
  <div class="related">
    <div class="related-title-container" id="rel-contain" v-if="!changeStyles">
      <p class="related-title-container-title" id="rel-contain-title">{{ $t("Related") }}</p>
    </div>
    <div class="related-component-container">
      <keep-alive v-if="!purgeContent">
        <contentsLisitng
          :listContent="contents"
          :changeStyles="changeStyles"
          :content="content"
          :playerInstance="playerInstance"
          :listType="listType"
          @contentAcquired="handleContentAcquired"
        ></contentsLisitng>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
  props: {
    content: {
      type: Object,
    },
    changeStyles: {
      type: Boolean,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      contents: null,
      localDisplayLang: null,
      listType: "",
      isMpegRequired: false,
      purgeContent: false,
    };
  },
  watch: {
    content(val) {
      // this.purgeContent = true;
      // this.purgeContent = false;
    },
  },
  computed: {
    fetchCardType() {
      return {
        cardName: "Trailer",
        type: "LANDSCAPE",
        height: "70px",
        playButton: { enablePlay: true, height: "40px", width: "40px" },
      };
    },
  },
  created() {
    console.log("Related carousel created");
    this.isMpegRequired = this.checkMpegRequired();
    this.listType = "Related";

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    // this.getRelatedContents();
  },
  mounted() {
    console.log("Related carousel mounted");
    // eventBus.$on("relatedcontents-response", this.relatedContentList);
  },
  beforeDestroy() {
    console.log("Related carousel destroyed");
  },
  methods: {
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    handleContentAcquired() {
      this.purgeContent = false;
    },
    // getRelatedContents() {
    //   let payload = {
    //     contentid: this.content.objectid,
    //     params: {
    //       displaylanguage: this.localDisplayLang
    //     }
    //   };
    //
    //   if (this.isMpegRequired == true) {
    //       payload.params.mpegtspackage = 'YES';
    //   }
    //   eventBus.$emit("getRelatedcontents", payload);
    // },
    // relatedContentList(payload) {
    //   if (!payload.reason) {
    //     this.contents = payload.data;
    //     this.contents.forEach(element => {
    //       element.isRelated = true;
    //     });
    //     // document.getElementById("vlive_popup").style.overflow = "auto";
    //   } else {
    //     this.contents = [];
    //   }
    // }
  },
  components: {
    contentsLisitng: () =>
      import(/* webpackChunkName: "contentsListing" */ "@/components/Templates/contentsListing.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./relatedCarousel.scss"
</style>
